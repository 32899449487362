<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <router-view :communityOwner="communityOwner" :communityName="communityName"></router-view>


</template>

<script>
    import * as axios from "@/axios-auth";
    export default {
        name: "CommunityBoards",
        props: {
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
        },
        created(){
            this.fetchBoards()
        },
        methods: {
            fetchBoards(){
                let loader = this.$loading.show({
                    onCancel: this.onLoaderCancel,
                });
                axios.jigo.get(`/v2/community/integrations/boards/getCommunityBoards/${this.communityOwner}/${this.communityName}`).then((resp) => {
                    this.$store.dispatch("setBoards", resp.data)
                    loader.hide()
                }).catch((err) => {
                    console.error("Error in fetching the board:", err)
                    loader.hide()
                })
            },
        }
    }
</script>

<style scoped>

</style>
